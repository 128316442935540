import { ButtonLink } from '../../common/components/Button'
import React from 'react'
import styles from './AppDownload.module.scss'

type AppDownloadProp = {|
  heading: string,
  link: {|
    text: string,
    to: string,
  |},
  appStore: {|
    image: string,
    ratings: string,
    link: string,
  |},
  googlePlay: {|
    image: string,
    ratings: string,
    link: string,
  |},
|}

type Props = {|
  appDownload: AppDownloadProp,
|}

export function AppDownload({ appDownload }: Props) {
  const { heading, link, appStore, googlePlay } = appDownload

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <div className={styles.content}>
          <h2 className={styles.heading}>{heading}</h2>
          <ButtonLink
            color="blue"
            text={link.text}
            linkExternal={link.to}
            external={true}
            openInNewTab
          />
        </div>
        <div className={styles.appDownloadContainer}>
          <div className={styles.appDownload}>
            <a className={styles.appDownloadLink} href={appStore.link}>
              <img src={appStore.image} />
            </a>
            <span>⭐️⭐️⭐️⭐️⭐️</span>
            <span className={styles.appDownloadRatings}>
              {appStore.ratings}
            </span>
          </div>
          <div className={styles.appDownload}>
            <a className={styles.appDownloadLink} href={googlePlay.link}>
              <img src={googlePlay.image} />
            </a>
            <span>⭐️⭐️⭐️⭐️⭐️</span>
            <span className={styles.appDownloadRatings}>
              {googlePlay.ratings}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
